import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'

const StyledForm = styled.form`
  padding:1em;
  input[type=textfield],select, textarea {
    width: 50vw;
    padding: 12px; 
    border: 1px solid #ccc; 
    border-radius: 4px;
    box-sizing: border-box; 
    margin-top: 6px;
    margin-bottom: 16px; 
    resize: vertical
  }
  button {
    width:20vmin;
    background-color: #45a049;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: #45a049;
  }
`

const ContactPage = () => {

  return (
    <Layout>
      <h1>Contact Information</h1>
      <p>
        If you would like to contact me, please feel free to call me at <b>631-403-0782</b> or send me an email at <a href='mailto:ChristineColavito@Yahoo.com'><b>ChristineColavito@Yahoo.com</b></a>.
        {/* <br/> */}
        {/* In order to keep your personal information private, please do not include confidential or personal/private information in this form. This form is for general questions or messages to the practitioner. */}
      </p>
      {/* 
      <StyledForm onSubmit={handleSubmit} method='POST'>
        <input type='textfield' name='name' placeholder='Name...'/>
        <br/>
        <input type='textfield' name='number' placeholder='Phone Number...'/>
        <br/>
        <input type='textfield' name='email' placeholder='Email...'/>
        <br/>
        <textarea rows='4'type='textarea' name='message' placeholder='Message...'> </textarea>
        <br/>
        <br/>
        <button type='submit'>Submit</button>
      </StyledForm> */}
    </Layout>
  )
}

export default ContactPage